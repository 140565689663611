<!-- 报备驳回原因 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'报备驳回原因':'报备驳回原因'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <el-row>
        <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请输入内容"
            v-model="reason">
        </el-input>
      </el-row>
    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {Savereport_audit} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id: Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      reason:'',

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    console.log(this.data,'驳回')
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            indexes: this.form.indexes,
            status: '-1',
            reason: this.reason
          }
          Savereport_audit(this.data.driver_id,data).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg);
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
            }else {
              this.$message.error(res.msg)
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
